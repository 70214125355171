import React from "react";
import propTypes from "prop-types";
import { useSession } from "next-auth/client";

import HomePage from "../pages/home.jsx";
import Loading from "./Loading";

OnlyOnAuthorised.propTypes = {
  children: propTypes.oneOfType([
    propTypes.element,
    propTypes.arrayOf(propTypes.element),
  ]),
};

export default function OnlyOnAuthorised(props) {
  const { children } = props;
  const [session, loading] = useSession();

  if (loading) {
    return <Loading key="loading" />;
  }

  if (!session) {
    return (
      <HomePage key="home" />
    );
  }

  return children;
}
