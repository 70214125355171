import React from "react";
import Link from "next/link";
import { motion, AnimatePresence } from "framer-motion";

import { H3, H4, Text } from "@andertons/design-system/components/Text.jsx";
import { PrimaryButton, SecondaryButton } from "@andertons/design-system/components/Button.jsx";
import Container from "@andertons/design-system/components/Container.jsx";
import logo from "@andertons/design-system/components/img/andertons-extra-logo-full.png";
import TextContainer from "@andertons/design-system/components/TextContainer.jsx";

import HomePageLayout from "../components/layouts/HomePage";
import StatusError from "../utils/StatusError";

HomePage.Layout = HomePageLayout;

export default function HomePage() {

  const [error] = React.useState(null);

  if (error) {
    const { statusText, status } = error.response || {};
    const err = new StatusError(statusText, status);
    throw err;
  }

  return (
    <div className="w-full h-full flex justify-center items-center overflow-y-scroll homepage-container">
      <AnimatePresence exitBeforeEnter>
        <Container
          as={motion.div}
          initial="hidden"
          animate="show"
          exit="hidden"
          key="home"
        >
          <TextContainer>
    
            <h1>
              <img alt="Andertons Extra Video Management Tool" src={logo} className="block mx-auto" />
            </h1>

            <H3 classNames="mt-4 text-center">
                Video Management Tool
            </H3>

            <H4 classNames="mt-10 mb-4">
              About this app
            </H4>
            <Text>
              Welcome to the Andertons Extra Video Management Tool. This website will
              allow you to easily manage Andertons videos on both YouTube and Andertons
              Extra simultaneously.
            </Text>
            <Text>
              Note: This app is for Andertons Extra video content administrators. If 
              you are not a nominated manager of Andertons videos, you won&apos;t be able 
              to use the app. Maybe you were looking for {' '} 
              <a href="https://andertonsextra.com" target="_blank" rel="noopener noreferrer">andertonsextra.com</a>?
            </Text>

            <H4 classNames="mt-10 mb-4">
            Your Data
            </H4>
            <Text>
              When you sign into this service, you are using your Google account
              to verify your identity. Andertons Music Co. receive your name and email 
              address. We use these details to check that you are an approved Andertons content manager for
              Extra and YouTube.
            </Text>
            <Text>
              To read more detail about how your data is used if you use this application, please visit our {' '}
              <Link href="/privacy"><a>Privacy Policy</a></Link> page.
            </Text>

            <div className="w-full flex justify-center mb-8">
              <PrimaryButton
                classNames="mx-1"
                data-cy="login"
              >
                <Link href="/login">
                  <a>
                    Login Now
                  </a>
                </Link>
              </PrimaryButton>

              <SecondaryButton
                classNames="mx-2"
                data-cy="visit-andertons-extra"
              >
                <a href="https://andertonsextra.com" target="_blank" rel="noopener noreferrer">
                  Go to andertonsextra.com
                </a>
              </SecondaryButton>
            </div>

          </TextContainer>

        </Container>
      </AnimatePresence>
    </div>
  );
}
