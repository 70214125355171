import React from "react";
import propTypes from "prop-types";
import Header from "../Header";

import { main } from "./Default.module.css";

Layout.propTypes = {
  children: propTypes.element,
};
export default function Layout(props) {
  const { children } = props;
  return (
    <>
      <Header key="header" />
      <main className={main}>{children}</main>
    </>
  );
}
