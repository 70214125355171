import React from "react";
import propTypes from "prop-types";
import { motion } from "framer-motion";

HomePageLayout.propTypes = {
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.element),
    propTypes.element,
  ]),
};
export default function HomePageLayout(props) {
  const { children } = props;
  return (
    <motion.main className="flex flex-col justify-items-start h-screen overflow-x-hidden">
      {children}
    </motion.main>
  );
}
