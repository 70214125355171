import "../styles/index.css";
import "react-dropzone-uploader/dist/styles.css";
import "react-datetime/css/react-datetime.css";

import React from "react";
import propTypes from "prop-types";
import { AnimatePresence } from "framer-motion";
import * as Sentry from "@sentry/node";
import { Provider } from "next-auth/client";
import getConfig from "next/config";

import Four0FourCard from "@andertons/design-system/components/404Card";
import FiveHundredCard from "@andertons/design-system/components/500Card";

import OnlyOnAuthorised from "../components/OnlyOnAuthorised";
import DefaultLayout from "../components/layouts/Default";

const { publicRuntimeConfig: env } = getConfig();

Sentry.init({
  enabled: process.env.NODE_ENV === "production",
  dsn: env.SENTRY_DSN,
  environment: "app",
});

export default class App extends React.Component {
  static propTypes = {
    Component: propTypes.func,
    pageProps: propTypes.object,
    err: propTypes.func,
    router: propTypes.object,
  };

  state = {
    hasError: false,
    status: null,
  };

  // We need to have this in place in order to ensure
  // That next.js doesn't try to convert any of our pages into static html
  static getInitialProps = () => ({});

  componentDidCatch(e) {
    // Track all runtime errors
    // This should only fire if we dont have a more granular handler lower down the component tree
    Sentry.captureException(e);
  }

  static getDerivedStateFromError(e) {
    // Status here, allows us to be able to show a 404 error
    // Rather than the more generic 500 error screen
    return { hasError: true, status: e.status };
  }

  isPagePublic(route) {
    // Define pages which are available to view without authentication
    const publicPages = ['/privacy', '/login'];
    return publicPages.includes(route);
  }

  render() {
    const { Component, pageProps = {}, err, router } = this.props;
    const { hasError, status } = this.state;

    const Layout = Component.Layout || DefaultLayout;

    if (hasError) {
      return (
        <div className="w-full h-full flex items-center justify-center">
          {status === 404 ? <Four0FourCard /> : <FiveHundredCard />}
        </div>
      );
    }

    let page = (
      // Layouts are assigned on a per-page basis with a default fallback
      // https://adamwathan.me/2019/10/17/persistent-layout-patterns-in-nextjs/
      <Layout key="layout">
        <AnimatePresence exitBeforeEnter>
          {/*
            We transition in each page. The page can define the entre/exit animations
            The key allows framer-motion to understand what to animate in/out and
            when an exit animation has completed
            https://reacttricks.com/animating-next-page-transitions-with-framer-motion/
          */}
          <Component {...pageProps} err={err} key={router.route} />
        </AnimatePresence>
      </Layout>
    );

    if(this.isPagePublic(router.route) === false) {
      // Most pages within our app require authentication.
      // The component below will render out a sign-in page
      // in the case the user is not currently authenticated
      page = <OnlyOnAuthorised>{ page }</OnlyOnAuthorised>
    }

    return (
      <Provider session={pageProps.session}>
        { page }
      </Provider>
    );
  }
}
